<template>
  <div id="attendance">
    <v-row>
      <v-col>
        <h2 class="mb-0 main-color">Attendance</h2>
        <p>Attendance should be marked before 5pm</p>
      </v-col>
    </v-row>
    <div class="text-center"></div>
    <div class="card height-auto v-application v-application--is-ltr">
      <div class="card-body">
        <v-row>
          <v-col cols="4" sm="2" id="attendancemenu py-0">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="selectedDate"
              transition="scale-transition"
              offset-y
              min-width="290px"
              :disabled="edit"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedDate"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  class="mt-0"
                ></v-text-field>
              </template>
              <v-date-picker v-model="selectedDate" :max="date" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">{{
                  $t("Cancel")
                }}</v-btn>
                <v-btn text color="primary" @click="saveDate">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4" sm="2" class="text-right pb-0">
            <v-select
              :items="classes"
              item-text="name"
              item-value="id"
              v-model="selectedClass"
              outlined
              @change="getLessons()"
              :disabled="edit"
              :label="$i18n.t('Class')"
            ></v-select>
          </v-col>

          <v-col cols="4" sm="2" class="pb-0">
            <v-select
              :items="lessons"
              item-text="name"
              item-value="id"
              v-model="selectedLesson"
              outlined
              @change="getAttendances"
              :disabled="edit"
              :label="$i18n.t('lesson')"
            ></v-select>
          </v-col>

          <v-col cols="6" sm="3" class="text-center pb-0">
            <label class="search" for="inpt_search">
              <input
                id="inpt_search"
                @focus="inputFocus"
                @blur="inputBlur"
                type="text"
                v-model="search"
                @change="searchStudents"
              />
            </label>
          </v-col>
          <v-col cols="6" sm="3" class="pb-0">
            <div class="btns text-right">
              <!-- <v-btn
                    class="main-color attended"
                    @click="attend"
                    v-if="edit == true"
                    >{{ $t("Attended") }}</v-btn
                  >
                  <v-btn
                    class="main-color absent"
                    @click="absent"
                    v-if="edit == true"
                    >Absent</v-btn
                  > -->
              <p class="edit-sec main-color mb-0" v-if="!edit">
                <v-icon color="#ffffff" @click="openEdit()" :title="$t('Edit')"
                  >edit</v-icon
                >
                {{ $t("Mark Absentees") }}
              </p>
              <p
                class="secondary-color mb-0"
                v-if="!absentees && attendances.length > 0"
              >
                {{ $t("Not done yet") }}
              </p>
              <p
                class="gray--text mb-0"
                v-if="absentees && attendances.length > 0"
              >
                {{ $t("Done") }}
              </p>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            class="pt-0"
            cols="6"
            sm="3"
            v-if="classes.length > 0 && selectedClass"
          >
            <p>
              Class:
              <span class="main-color">{{
                classes.find((item) => item.id == selectedClass).name
              }}</span>
            </p>
          </v-col>
          <v-col
            cols="6"
            sm="3"
            class="pt-0"
            v-if="lessons.length > 0 && selectedLesson"
          >
            <p>
              {{ $t("Lesson") }}:
              <span class="main-color">{{
                lessons.find((item) => item.id == selectedLesson).name
              }}</span>
            </p>
          </v-col>
        </v-row>
        <v-data-table
          v-if="attendances.length > 0"
          v-model="selected"
          :headers="headers"
          :items="attendances"
          :single-select="singleSelect"
          sort-by.sync="name"
          item-key="name"
          class="elevation-1 level1"
          hide-default-footer
          :show-select="edit == true ? true : false"
          :search="search"
          :items-per-page="attendances.length"
        >
          <template v-slot:item.attended="{ item }">
            <v-icon
              v-if="item.attended && edit == false"
              color="#5fb870"
              :title="$t('Attendant')"
              >check</v-icon
            >
            <v-icon
              v-if="!item.attended && edit == false"
              color="error"
              :title="$t('Absent')"
              >close</v-icon
            >
            <v-checkbox
              v-model="item.attended"
              v-if="edit == true"
            ></v-checkbox>
          </template>

          <template v-slot:item.comment="{ item }">
            <v-text-field
              v-model="item.comment"
              v-if="edit && selectedType =='textbox'"
              solo
            ></v-text-field>
            <v-select v-if="edit && selectedType =='presetList'" :class="currentAppLocale == 'en' ? 'dir-left' : 'dir-right' "
              :items="listOptions" v-model="item.comment" solo></v-select>
            <span v-if="!edit">{{ item.comment }}</span>
          </template>
        </v-data-table>

        <div v-else>
          <v-alert dense type="info">
            <span v-if="noData && waiting == false">
              {{ $t("There is no data available") }}
            </span>
            <span v-else>
              {{ $t("Loading Data ...") }}
            </span>
          </v-alert>
        </div>
      </div>
      <v-card-actions v-if="edit">
        <v-spacer></v-spacer>

        <v-btn class="modal-btn-cancel cancelBtn" @click="cancel()">{{
          $t("Close")
        }}</v-btn>
        <v-btn
          class="modal-btn-save saveBtn"
          :loading="loading"
          @click="save()"
          >{{ $t("Save") }}</v-btn
        >
      </v-card-actions>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "classAttendance",
  data() {
    return {
      date: '',
      search: "",
      absentees: "",
      edit: false,
      singleSelect: false,
      selected: [],
      loading: false,
      waiting: false,
      noData: false,
      headers: [
        {
          text: this.$i18n.t("Code"),
          sortable: false,
          value: "code",
        },
        { text: this.$i18n.t("Name"), value: "name" },
        { text: this.$i18n.t("Gender"), value: "gender", sortable: false },
        {
          text: this.$i18n.t("Attendance"),
          value: "attended",
          sortable: false,
        },
        { text: this.$i18n.t("Comment"), value: "comment", sortable: false },
      ],
      attendances: [],
      classes: [],
      lessons: [],
      selectedClass: "",
      selectedLesson: "",
      selectedDate: "",
      menu: false,
      modal: false,
      selectedType:"",
      listOptions:[],
    };
  },
  watch: {
    selectedDate: function () {
      this.getClasses();
      this.attendances = [];
      this.getAttendances();
    },
  },
  methods: {
    searchStudents() {},
    getAttendanceConfiguration() {
      this.loading = true;
      this.$http
        .get(this.getApiUrl + "/school/attendance-comment-configuration", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.loading = false;
          if (response.data.status.error == false) {
            this.selectedType = response.data.data.selectedType;
            this.listOptions = response.data.data.translatedList;

          }
        });
    },
    saveDate() {
      this.$refs.menu.save(this.selectedDate);
      this.getClasses();
    },
    openEdit() {
      this.edit = true;
      if (this.absentees == false) {
        this.attendances.forEach((item) => {
          item.attended = true;
        });
      }
    },

    getDates() {
      axios
        .get(this.getApiUrl + "/attendance/getDatesForFilter", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.selectedDate = response.data.data.default;
          this.getClasses();
        });
    },

    cancel() {
      this.edit = false;

      this.getAttendances();
    },
    save() {
      this.loading = true;
      axios
        .post(
          this.getApiUrl + "/attendance/store/" + this.selectedClass,
          {
            students: this.attendances,
            lesson_id: this.selectedLesson,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (!response.data.status.error) {
            this.edit = false;
            this.getAttendances();
            this.loading = false;
          }
        });
    },
    getClasses() {
      axios
        .get(
          this.getApiUrl +
            "/attendance/getClassesForFilter?date=" +
            this.selectedDate,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.classes = response.data.data ? response.data.data : [];
          if (this.classes.length > 0) {
            this.selectedClass = this.classes[0].id;
            this.getLessons();
          }
          // if (this.classes.length > 0) {
          //   this.selectedClass = this.classes[0].id;
          // }
          // this.getAttendances();
        });
    },
    getAttendances() {
      if (
        this.selectedClass != "" &&
        this.selectedDate != "" &&
        this.selectedLesson != ""
      ) {
        this.attendances = [];
        this.waiting = true;
        axios
          .get(
            this.getApiUrl +
              "/attendance?class_id=" +
              this.selectedClass +
              "&date=" +
              this.selectedDate +
              "&lesson_id=" +
              this.selectedLesson,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.waiting = false;
            this.attendances = response.data.data.students;
            if (this.attendances.length > 0) {
              this.noData = false;
            } else {
              this.noData = true;
            }
            this.absentees = response.data.data.absentees;
          });
      }
    },
    getLessons() {
      this.selectedLesson = "";
      this.attendances = [];
      if (this.selectedClass && this.selectedDate) {
        axios
          .get(
            this.getApiUrl +
              "/attendance/getLessonsOfClassForTeacher?class_id=" +
              this.selectedClass +
              "&date=" +
              this.selectedDate,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.lessons = response.data.data;
            if (this.lessons.length > 0) {
              this.selectedLesson = this.lessons[0].id;
            }
            this.getAttendances();
          });
      }
    },
    attend() {
      if (this.attendances.length > 0) {
        this.attendances.forEach((item) => {
          item.attended = true;
        });
      }
    },
    absent() {
      if (this.attendances.length > 0) {
        this.attendances.forEach((item) => {
          item.attended = false;
        });
      }
    },
  },
  mounted() {
    this.getDates();
    this.getAttendanceConfiguration();
    if ((new Date().getMonth() + 1) < 10) {
      this.date = new Date().getFullYear() + '-0' + (new Date().getMonth() + 1) + '-' + new Date().getDate();
    } else {
      this.date = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

.label {
  margin-top: 1rem;
}

.class-scroll {
  margin-top: 1rem;
}
.btns {
  margin-top: 1rem;
}
</style>
